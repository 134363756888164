<!--电梯模块--通行记录页--表格组件-->
<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="scroll"
      :pagination="pagination"
      :loading="loading"
      @change = "onchange"
    >
     <span slot="command" slot-scope="text">
        {{getCmd(text)}}
     </span>
     <span slot="result" slot-scope="text">
        <a-badge :status="text|statusTypeFilter" :text ="changeStaus(text)"/></a-badge>
     </span>
    </a-table>
  </div>
</template>

<script>
const statusMap = {
  0:{
    status:'error',
    text:'elevatorRemoteRecords.s5'
  },
  1:{
   status:'error',
   text:'elevatorRemoteRecords.s5'
 },
  2:{
   status:'error',
   text:'elevatorRemoteRecords.s5'
 },
 200:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 201:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 200:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 201:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 200:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 201:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 202:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 203:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 204:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 205:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 206:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 207:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 208:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 209:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 210:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 211:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 212:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 213:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  },
 214:{
   status:'success',
   text:'elevatorRemoteRecords.s5'
 },
 215:{
    status:'success',
    text:'elevatorRemoteRecords.s6'
  }
}
const statusCmpMap = {
  3:{
    status:'error',
    text:'远程开锁'
  },
  4:{
    status:'error',
    text:'室内召梯'
  },
  10:{
    status:'processing',
    text:'互访'
  },
  11:{
    status:'success',
    text:'微信召梯'
  }
}
import {
  tableSort
} from "@/utils/utils";
export default {
  name: "remoteHistoryTable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    pagination: {
      type: Object,
    },
    scroll:{
      type: Object,
    }
  },
  data() {
    return {
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 50,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: this.$t('elevatorRemoteRecords.td1'),
          dataIndex: "command",
          scopedSlots: { customRender: "command" },
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "command");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td2'),
          dataIndex: "datetime",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "datetime");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td3'),
          dataIndex: "responsedatetime",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "responsedatetime");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td4'),
          dataIndex: "personName",
          width: 120,
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td5'),
          dataIndex: "personNum",
          width: 120,
          sorter: (a, b) => {
            return tableSort(a, b, "personNum");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td6'),
          dataIndex: "personID",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "personID");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td7'),
          dataIndex: "roomNum",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "roomNum");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td8'),
          dataIndex: "visitRoom",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "visitRoom");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td9'),
          dataIndex: "surveyedRoom",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "surveyedRoom");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td10'),
          dataIndex: "description",
          width: 240,
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorRemoteRecords.td11'),
          dataIndex: "result",
          scopedSlots: { customRender: "result" },
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "result");
          },
          fixed: "right",
        },
      ],
    };
  },
  
  watch: {
  },
  methods: {
    onchange(pagination){
      this.$emit("update:pagination", pagination);
      this.$emit("refresh",false);
    },
    // 获取结果
    changeStaus(text){
      switch(text){
        case 0:return this.$t('elevatorRemoteRecords.s6') ;break;
        case 1:return this.$t('elevatorRemoteRecords.s7') ;break;
        case 2:return this.$t('elevatorRemoteRecords.s8') ;break;
        case 200:return this.$t('elevatorRemoteRecords.s9') ;break;
        case 201:return this.$t('elevatorRemoteRecords.s10') ;break;
        case 202:return this.$t('elevatorRemoteRecords.s11') ;break;
        case 203:return this.$t('elevatorRemoteRecords.s12') ;break;
        case 204:return this.$t('elevatorRemoteRecords.s13') ;break;
        case 205:return this.$t('elevatorRemoteRecords.s14') ;break;
        case 206:return this.$t('elevatorRemoteRecords.s15') ;break;
        case 207:return this.$t('elevatorRemoteRecords.s16') ;break;
        case 208:return this.$t('elevatorRemoteRecords.s17') ;break;
        case 209:return this.$t('elevatorRemoteRecords.s18') ;break;
        case 210:return this.$t('elevatorRemoteRecords.s19') ;break;
        case 211:return this.$t('elevatorRemoteRecords.s20') ;break;
        case 212:return this.$t('elevatorRemoteRecords.s21') ;break;
        case 213:return this.$t('elevatorRemoteRecords.s22') ;break;
        case 214:return this.$t('elevatorRemoteRecords.s23') ;break;
        case 215:return this.$t('elevatorRemoteRecords.s24') ;break;
      }
    },
    // 获取指令号
    getCmd(text){
      switch(text){
        case 3:return this.$t('elevatorRemoteRecords.s2') ;break;
        case 4:return this.$t('elevatorRemoteRecords.s3') ;break;
        case 10:return this.$t('elevatorRemoteRecords.s4') ;break;
        case 11:return this.$t('elevatorRemoteRecords.s5') ;break;
      }
    }
  },
  filters: {
    statusTypeFilter(type){
      return statusMap[type].status
    }
  }
};
</script>

<style scoped>

</style>
<!--电梯模块--卡记录页-->
<template>
  <div id="alarm">
    <div class="header">
      <my-headertitle>{{this.$t('elevatorRemoteRecords.a1')}}</my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('elevatorRemoteRecords.a2')">
             <a-select
                v-model="form.cmd"
                style="width: 100px"
              >
                <a-select-option
                  :value="tiem.value"
                  v-for="(tiem, value) in cmdoptions"
                  :key="value"
                  >{{  $t(tiem.name) }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('elevatorRemoteRecords.a3')">
              <a-select
                v-model="form.result"
                style="width: 120px"
              >
                <a-select-option
                  :value="tiem.value"
                  v-for="(tiem, value) in options"
                  :key="value"
                  >{{  $t(tiem.name) }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('elevatorRemoteRecords.a4')" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @openChange="handleStartOpenChange"
                @change="handleCalendarChange"
                @ok="handleStartOk"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('elevatorRemoteRecords.a5')" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="refresh(true)">{{this.$t('elevatorRemoteRecords.a6')}}</a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="main" ref="main">
      <my-tabletitle>{{this.$t('elevatorRemoteRecords.a7')}}</my-tabletitle>
      <my-remotehistoryTable :data="data" :loading="loading" :pagination.sync="pagination" :scroll.sync="scroll" @refresh = "refresh"></my-remotehistoryTable>
    </div>
  </div>
</template>
<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import remotehistoryTable from "./components/remotehistoryTable";
import moment from "moment";
import { getApiRemoteControlHistoryPage,getbuildsiteId } from "../../api/elevator";
export default {
  name: "histroy",
  data() {
    return {
      endOpen: false,
      form: {
        cmd: 0,
        result: 44,
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      scroll:{ x: 1400 ,y:240},
      options: [{ value: 44, name: "elevatorRemoteRecords.s1" },{ value: 0, name: "elevatorRemoteRecords.s6"  },{ value: 1, name: "elevatorRemoteRecords.s7"  },{ value: 2, name: "elevatorRemoteRecords.s8"  }
      ,{ value: 200, name: "elevatorRemoteRecords.s9"  }, { value: 201, name: "elevatorRemoteRecords.s10"  }
      ,{ value: 202, name: "elevatorRemoteRecords.s11"  },{ value: 203, name: "elevatorRemoteRecords.s12"  }
      ,{ value: 204, name: "elevatorRemoteRecords.s13"  },{ value: 205, name: "elevatorRemoteRecords.s14"  }
      ,{ value: 206, name: "elevatorRemoteRecords.s15"  },{ value: 207, name: "elevatorRemoteRecords.s16"  }
      ,{ value: 208, name: "elevatorRemoteRecords.s17"  },{ value: 209, name: "elevatorRemoteRecords.s18"  }
      ,{ value: 210, name: "elevatorRemoteRecords.s19"  },{ value: 211, name: "elevatorRemoteRecords.s20"  }
      ,{ value: 212, name: "elevatorRemoteRecords.s21"  },{ value: 213, name: "elevatorRemoteRecords.s22"  }
      ,{ value: 214, name: "elevatorRemoteRecords.s23"  },{ value: 215, name: "elevatorRemoteRecords.s24"  }],
      cmdoptions:[{ value: 0, name: "elevatorRemoteRecords.s1" },{ value: 3, name: "elevatorRemoteRecords.s2" },{ value: 4, name: "elevatorRemoteRecords.s3" },{ value: 10, name: "elevatorRemoteRecords.s4" },{ value: 11, name: "elevatorRemoteRecords.s5" }],
      data: undefined,
      data1: undefined,
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          { required: true, message: "", trigger: "change" },
        ],
        date2: [
          { required: true, trigger: "change" },
        ],
      },
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => this.$t('elevatorRemoteRecords.a23') + ` ${total} `+ this.$t('elevatorRemoteRecords.a24'), //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        current:1,
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      id:{
        client:0,
        area:0,
        build:0
      }
    };
  },
  async created() {
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
    }).catch((err) => {
        console.log("访问站点转换出错", err);
    });
  },
  mounted(){
    // this.tableHeight();
    window.addEventListener("resize",this.tableHeight)
  },
  beforeDestroy(){
    window.addEventListener("resize",this.tableHeight)
  },
  computed: {
  },
  methods: {
    moment,
    // 表格高度计算
    tableHeight(){
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 172;
      this.scroll.y = tableHeight
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      this.startDate = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
    },
    handleStartOk(){
      if(moment(this.startDate).endOf('month')<moment().endOf("day")){
        this.form.date2 = moment(this.startDate).endOf("month");
      }else{
        this.form.date2 = moment().endOf("day");
      }
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            "pageInfo": {
              "wxc_clientId": this.id.client,
              "wxc_areaId": this.id.area,
              "wxc_bdId": this.id.build,
              "wxc_operResult": this.form.result,
              "wxc_ProtocolCmd": this.form.cmd
            },
            "startDate": moment(this.form.date1).format("YYYY-MM-DD HH:mm:ss"),
            "endDate": moment(this.form.date2).format("YYYY-MM-DD HH:mm:ss"),
            "pageNo": this.pagination.current,
            "pageSize": this.pagination.pageSize,
            "sortField": "string",
            "ascSortOrder": true
          };
          getApiRemoteControlHistoryPage(params)
            .then((res) => {
              this.loading = false;
              let data = res.data.list;
              this.pagination.total = res.data.total
              this.pagination.current = res.data.pageNo
              let List = []
              for (let i = 0; i < data.length; i++) {
                let temp = {
                  key: i + 1,
                  command:data[i].wxc_ProtocolCmd, //指令号
                  datetime: data[i].wxc_receiveDate === null ? '-' : moment(data[i].wxc_receiveDate).format("YYYY-MM-DD HH:mm:ss"), // 请求时间
                  responsedatetime:  data[i].wxc_operDate === null ? '-' : moment(data[i].wxc_operDate).format("YYYY-MM-DD HH:mm:ss"), // 响应时间
                  personName: data[i].cardUser===null?'-':data[i].cardUser,
                  personNum: data[i].ps_num===null?'-':data[i].ps_num,
                  personID:  data[i].wxc_userId===null?'-':data[i].wxc_userId,
                  roomNum: data[i].blongRoomNum===null?'-':data[i].blongRoomNum,
                  visitRoom: data[i].wxc_roomID===null?'-':data[i].wxc_roomID,
                  surveyedRoom:data[i].wxc_roomID_to===null?'-':data[i].wxc_roomID_to,
                  description:data[i].wxc_operRemark===null?'-':data[i].wxc_operRemark,
                  result:data[i].wxc_operResult
                }
                List.push(temp)
              }
              this.data = List;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    refresh(val) {
      if(val){
         this.pagination.current = 1
      }
      this.inquire()
    },
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.form.date2;
      if(endValue===null){
        return startValue >= moment().endOf()
      }
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
    // 初始日期/时间选择弹出日历和关闭日历的回调
    handleStartOpenChange(open) {
      if (!open) {
        // this.endOpen = true;
      }else{
        this.form.date2 = null
      }
    },
    // 终止日期/时间选择弹出日历和关闭日历的回调
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
  },
  filters: {
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-remotehistoryTable": remotehistoryTable,
  },
};
</script>

<style scoped>
#alarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 140px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 140px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
}
.fool {
  width: 100px;
  height: 10px;
}
button {
  width: 120px;
}
</style>